import React, { ReactNode, useCallback, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    Group,
    Loading,
    Text,
    TextType,
    Heading,
    Level,
    useTheme,
    Button,
    Variant,
    Pill,
    Composite,
    Icon,
    Modal,
    IconName,
    Size,
    ProgressIndicator,
    ProgressType,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { StatusBoxRoot, TopSection, PillCotainer } from './charger-info.styles';
import { OCCPStatus, ConnectorStatus, Connector } from '../../models/connector';
import i18n from '../../i18n';
import { DO_CONNECTOR_ACTION, GET_CONNECTOR } from './charger-info.queries';
import { NotificationContext } from '../../utils/notification';
import { getStatus } from '../../utils/status';
import { IconContainer } from '../../fractions/group-section/group-section.styles';
import { getVisualStatus } from '../../utils/visual-status';
import { getOCPPStatus } from '../../utils/occp-status';

function StatusBox({
    children,
    status,
    inactive,
}: {
    children?: ReactNode;
    status: OCCPStatus;
    inactive: boolean;
}) {
    return (
        <StatusBoxRoot status={status} inactive={inactive}>
            {children}
        </StatusBoxRoot>
    );
}

interface ConnectorResponse {
    connector: Connector;
}

export function ChargerInfo() {
    const { id, connectorAlias } = useParams<{ id: string; connectorAlias: string }>();
    const { add: addNotification } = useContext(NotificationContext);

    const theme = useTheme();
    const history = useHistory();

    const { data, loading } = useQuery<ConnectorResponse>(GET_CONNECTOR, {
        variables: { id, connectorAlias },
        fetchPolicy: 'network-only',
    });

    const [doConnectorAction, { loading: actionLoading }] = useMutation(DO_CONNECTOR_ACTION);

    const { connector } = data ?? {};

    const { hbTimeout = false, hbLastAlive } = connector?.operationalData || {};
    const { version = '', status = '', chargingState = '' } =
        connector?.operationalData?.ocpp ?? {};
    const ocpp = getOCPPStatus(version, status, chargingState);
    const isRestarting = connector?.status === ConnectorStatus.RESTARTING;

    const triggerConnectorAction = useCallback(
        async (action: string) => {
            const { data: d } = (await doConnectorAction({
                variables: { id: connectorAlias, action },
            })) as { data: { connectorAction: { ok: boolean } } };
            const okStatus = d.connectorAction.ok ? 'OK' : 'NOK';
            const message = i18n.t(`ChargerAction.${action.toLocaleUpperCase()}.${okStatus}`);
            addNotification({ message });
        },
        [doConnectorAction, connectorAlias, addNotification]
    );

    const doStart = useCallback(async () => {
        triggerConnectorAction('start');
    }, [triggerConnectorAction]);

    const doStop = useCallback(() => {
        triggerConnectorAction('stop');
    }, [triggerConnectorAction]);

    const { titleColor, iconColorActive, icon, start, stop, help } = getVisualStatus(
        ocpp,
        theme,
        hbTimeout,
        undefined,
        connector?.type
    );

    return (
        <Modal
            width="343px"
            onClosePress={() => history.push(`/charge-system/${id}`)}
            showModal={!!connectorAlias}
            titleContent={
                <Composite>
                    {isRestarting ? (
                        <ProgressIndicator progress={10} type={ProgressType.DONUT_LOADING} />
                    ) : (
                        <IconContainer color={iconColorActive}>
                            <Icon icon={icon as IconName} color="#fff" size={24} />
                        </IconContainer>
                    )}
                    <Heading color={hbTimeout ? titleColor : undefined} level={Level.h3}>
                        {connector?.displayName || connector?.alias}
                    </Heading>
                </Composite>
            }
            bodyContent={
                <Group flex>
                    {loading ? (
                        <Loading minHeight="100px" />
                    ) : (
                        <StatusBox status={ocpp} inactive={hbTimeout || isRestarting}>
                            <TopSection>
                                <Heading level={Level.h4}>
                                    {i18n.t(
                                        `ChargerGroups.ConnectorStatus.${getStatus(
                                            hbTimeout,
                                            ocpp,
                                            isRestarting
                                        )}.Title`
                                    )}
                                </Heading>
                                <Text alignment="center" type={TextType.description}>
                                    {i18n.t(
                                        `ChargerGroups.ConnectorStatus.${getStatus(
                                            hbTimeout,
                                            ocpp,
                                            isRestarting
                                        )}.Description`,
                                        { date: hbLastAlive }
                                    )}
                                </Text>
                            </TopSection>
                            {start && (
                                <Button
                                    name="start-button"
                                    variant={Variant.PRIMARY}
                                    onClick={doStart}
                                    fillParent={false}
                                    size={Size.SMALL}
                                    text={i18n.t('ChargerInfo.Start')}
                                    loading={actionLoading}
                                />
                            )}
                            {stop && (
                                <Button
                                    name="stop-button"
                                    variant={Variant.SECONDARY}
                                    fillParent={false}
                                    size={Size.SMALL}
                                    onClick={doStop}
                                    text={i18n.t('ChargerInfo.Stop')}
                                    loading={actionLoading}
                                />
                            )}
                            {help && (
                                <Button
                                    name="get-help-button"
                                    variant={Variant.SECONDARY}
                                    fillParent={false}
                                    size={Size.SMALL}
                                    text={i18n.t('ChargerInfo.GetHelp')}
                                    onClick={() => {
                                        history.replace('/support');
                                    }}
                                />
                            )}
                            {ocpp !== OCCPStatus.OFFLINE && (
                                <PillCotainer>
                                    <Pill
                                        items={[version, status, chargingState]}
                                        tooltip={i18n.t(
                                            `ChargerGroups.ConnectorStatus.${ocpp}.OCCPText`
                                        )}
                                    />
                                </PillCotainer>
                            )}
                        </StatusBox>
                    )}
                </Group>
            }
            actionContent={
                <Composite justify="end">
                    <Button
                        icon="settings"
                        name="charger-settings"
                        onClick={() => {
                            history.replace(
                                `/charge-system/${id}/charger/${connectorAlias}/details/connector`
                            );
                        }}
                        variant={Variant.SECONDARY}
                        tooltip={i18n.t('ChargeSystem.Settings')}
                        size={Size.TINY}
                        fillParent={false}
                    />
                </Composite>
            }
        />
    );
}

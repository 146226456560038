import { SplitScreen, styled } from '@defa/defa-component-library';

export const Root = styled(SplitScreen)``;

export const Hero = styled.div`
    background-color: ${({ theme }) => theme.background.default};
    background-image: url('/images/car_charging.png');
    background-size: cover;
    min-height: 180px;
    display: flex;
    align-items: flex-end;
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(4)};
`;

export const Message = styled.div`
    background-color: ${({ theme }) => theme.background.default};
    padding: ${({ theme }) => theme.spacing(4)};
    border-radius: ${({ theme }) => theme.spacing(2)};
`;

import { Theme } from '@defa/defa-component-library';
import { ConectorVisualStatus, ConnectorType, OCCPStatus } from '../../models';
import { getConnectorTypeIcon } from '../connector-type-icon';

const defaBlue = '#4361EE';

export function getVisualStatus(
    status: OCCPStatus | string,
    theme: Theme,
    hbTimeout: boolean = false,
    isRestarting: boolean = false,
    connectorType?: ConnectorType
): ConectorVisualStatus {
    const defaultVisualStatus: ConectorVisualStatus = {
        background: theme.cardBackground,
        titleColor: theme.textColor,
        descriptionColor: theme.cardSupportingTextColor,
        iconColor: '#fff',
        iconBackgroundColor: theme.successColor,
        iconColorActive: theme.successColor,
        icon: getConnectorTypeIcon(connectorType),
        iconActive: 'boltCircle',
        start: false,
        stop: false,
        help: false,
    };

    // If heartbeat timeout, offline, or restarting, return disabled style.
    if (hbTimeout || status === OCCPStatus.OFFLINE || isRestarting) {
        return {
            ...defaultVisualStatus,
            help: true,
            titleColor: theme.buttonTextColorDisabled,
            iconColor: '#fff',
            iconBackgroundColor: theme.buttonTextColorDisabled,
            iconColorActive: theme.buttonTextColorDisabled,
        };
    }

    switch (status) {
        case OCCPStatus.OCPP20_UNAVAILABLE:
        case OCCPStatus.OCPP15_UNAVAILABLE:
        case OCCPStatus.OCPP16_UNAVAILABLE:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: '#fff',
                iconBackgroundColor: theme.buttonTextColorDisabled,
                iconColorActive: theme.buttonTextColorDisabled,
                titleColor: theme.buttonTextColorDisabled,
            };

        case OCCPStatus.OCPP15_OCCUPIED:
            return {
                ...defaultVisualStatus,
                start: true,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };

        case OCCPStatus.OCPP20_FAULTED:
        case OCCPStatus.OCPP15_FAULTED:
        case OCCPStatus.OCPP16_FAULTED:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: theme.errorColor,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: theme.errorColor,
                background: theme.errorColor,
            };

        case OCCPStatus.OCPP20_OCCUPIED_EVCONNECTED:
        case OCCPStatus.OCPP20_OCCUPIED_IDLE:
        case OCCPStatus.OCPP16_PREPARING:
            return {
                ...defaultVisualStatus,
                start: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                background: defaBlue,
            };

        case OCCPStatus.OCPP20_OCCUPIED_CHARGING:
        case OCCPStatus.OCPP16_CHARGING:
            return {
                ...defaultVisualStatus,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                background: defaBlue,
            };

        case OCCPStatus.OCPP16_FINISHING:
        case OCCPStatus.FACILITY_FINISHING:
            return {
                ...defaultVisualStatus,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                background: defaBlue,
            };

        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEVSE:
        case OCCPStatus.OCPP20_OCCUPIED_SUSPENDEDEV:
        case OCCPStatus.OCPP16_SUSPENDED_EVSE:
        case OCCPStatus.OCPP16_SUSPENDED_EV:
            return {
                ...defaultVisualStatus,
                stop: true,
                iconColor: defaBlue,
                iconBackgroundColor: '#fff',
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };

        case OCCPStatus.OCPP20_RESERVED:
        case OCCPStatus.OCPP15_RESERVED:
        case OCCPStatus.OCPP16_RESERVED:
            return {
                ...defaultVisualStatus,
                help: true,
                iconColor: defaBlue,
                titleColor: '#fff',
                descriptionColor: '#fff',
                iconBackgroundColor: '#fff',
                iconColorActive: defaBlue,
                background: defaBlue,
            };

        default:
            return defaultVisualStatus;
    }
}

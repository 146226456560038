import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SplitFactory } from '@splitsoftware/splitio-react';

import { GlobalStyle, themes, ThemeProvider } from '@defa/defa-component-library';
import { setContext } from 'apollo-link-context';

import { Login } from './screens/login/login';
import { AuthProvider } from './auth';
import { PrivateRoute } from './utils/private-route';
import { Portal } from './screens/portal';
import { Root } from './app.styles';
import { THEME_INDEX } from './constants';
import { CreateAccount } from './screens/create-account/create-account';
import { updateLocale } from './i18n';
import { AcceptInvitation } from './screens/accept-invitation';
import { Welcome } from './screens/welcome';
import { ResetPassword } from './screens/reset-password';
import { ResetPasswordResponse } from './screens/reset-password/reset-password-response';
import { ResetPasswordNewPassword } from './screens/reset-password/reset-password-new-password';
import { NotificationProvider } from './utils/notification/notification';
import { AnonymousInvite } from './screens/anonymous-invite';

const splitSdkConfig = {
    core: {
        authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY || '',
        key: 'default-user-id',
    },
};

function getGraphUrl() {
    const url = new URL(window.location.href);
    url.host = url.host.replace('portal', 'graphql');
    url.pathname = '/graphql';
    return url.href;
}

const httpLink = createHttpLink({
    uri:
        process.env.REACT_APP_GRAPH_API_URL ||
        (window.location.host.includes('localhost')
            ? 'https://dev.graphql.cloudcharge.se/graphql'
            : getGraphUrl()),
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('cc-t');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token || '',
        },
    };
});

const errorLink = onError(() => {
    const reloadCount = parseInt(sessionStorage.getItem('ErrorReloads') || '0', 10);
    // Refresh the page incase user has been loged out
    if (reloadCount < 3) {
        sessionStorage.setItem('ErrorReloads', `${Number(reloadCount) + 1}`);
        window.location.reload();
    }
});

const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink as any) as any]),
    cache: new InMemoryCache({
        addTypename: false,
    }),
});

// Setting Application language based on Browser language
updateLocale(navigator.language);

export const App: React.FunctionComponent = () => (
    <Root>
        <ThemeProvider theme={themes[THEME_INDEX]}>
            <GlobalStyle />
            <SplitFactory config={splitSdkConfig}>
                <NotificationProvider>
                    <ApolloProvider client={client}>
                        <AuthProvider apolloClient={client}>
                            <Switch>
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/login/:msg" component={Login} />
                                <Route exact path="/reset-password" component={ResetPassword} />
                                <Route
                                    path="/reset-password/:email"
                                    component={ResetPasswordResponse}
                                />
                                <Route
                                    path="/passwordreset.html"
                                    component={ResetPasswordNewPassword}
                                />
                                <Route exact path="/create-account" component={CreateAccount} />
                                <Route
                                    exact
                                    path="/accept-invitation"
                                    component={AcceptInvitation}
                                />
                                <Route exact path="/anonymous" component={AnonymousInvite} />
                                <Route exact path="/welcome" component={Welcome} />
                                <PrivateRoute path="/" component={Portal} />
                            </Switch>
                        </AuthProvider>
                    </ApolloProvider>
                </NotificationProvider>
            </SplitFactory>
        </ThemeProvider>
    </Root>
);

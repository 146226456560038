import { ConnectorStatus, OCCPStatus } from '../../models';

export function getOCPPStatus(
    version: string = '',
    status: string = '',
    state: string = ''
): OCCPStatus {
    if (status === ConnectorStatus.FACILITY_FINISHING) {
        return OCCPStatus.FACILITY_FINISHING;
    }
    const key = [version, status, state.toUpperCase()].filter(Boolean).join('_') as OCCPStatus;
    if (Object.keys(OCCPStatus).includes(key)) {
        return OCCPStatus[key];
    }
    return OCCPStatus.OFFLINE;
}

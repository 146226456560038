import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Dropdown,
    Group,
    Heading,
    InformationType,
    Level,
    TextInput,
    useTheme,
    Text,
    TextType,
    Composite,
    Icon,
} from '@defa/defa-component-library';

import { useQueryParam } from '../../utils/hooks';
import {
    ACCEPT,
    AcceptInvitationResponse,
    VALIDATE,
    ValidateInvitationResponse,
} from './anonymous-invite.queries';
import { Root, Hero, Message } from './anonymous-invite.styles';
import { BrandPanel } from '../../fractions/brand-panel';
import i18n from '../../i18n';
import { validateEmail } from '../../utils/email';
import { AnonymousSetupResponseStatus } from '../../models';
import { MarkDown } from '../../fractions/mark-down';

export const AnonymousInvite: React.FunctionComponent = () => {
    const theme = useTheme();
    const query = useQueryParam();
    const token = query.get('token');

    const [email, setEmail] = useState<string>('');
    const [done, setDone] = useState<boolean>(false);
    const [error, setError] = useState<AnonymousSetupResponseStatus | undefined>();
    const [countryCode, setCountryCode] = useState<string>('');
    const isEmailOk = validateEmail(email);
    const isOk = email && countryCode;

    const { data, loading, error: validateError, refetch } = useQuery<ValidateInvitationResponse>(
        VALIDATE,
        {
            fetchPolicy: 'network-only',
            variables: { token },
        }
    );
    const { anonymousInvite, countries = [] } = data ?? {};
    const { numOfConnectors, projectName, addressDetails, status } = anonymousInvite || {};

    const [acceptInvite, { loading: loadingSave }] = useMutation<AcceptInvitationResponse>(ACCEPT);

    const submit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();
            const { data: accept } = await acceptInvite({
                variables: {
                    email,
                    countryCode,
                    token,
                },
            });
            if (accept?.acceptAnonymousInvite?.status === AnonymousSetupResponseStatus.SUCCESS) {
                setDone(true);
            } else {
                setError(AnonymousSetupResponseStatus.INVALID);
            }
        },
        [acceptInvite, email, countryCode, token]
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (validateError) {
            setError(AnonymousSetupResponseStatus.INVALID);
        }
    }, [validateError]);

    const hasError =
        status === AnonymousSetupResponseStatus.INVALID ||
        status === AnonymousSetupResponseStatus.NO_TOKEN ||
        error;
    const showForm = !done && !hasError && status === AnonymousSetupResponseStatus.SUCCESS;
    return (
        <>
            <Root
                first={
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                    </Group>
                }
                secondSideLoading={loading}
                second={
                    <Group minWidth="480px" maxWidth="480px" form onSubmit={submit}>
                        <Hero>
                            <Heading color={theme.foreground.light} level={Level.h1}>
                                {i18n.t('AnonymousInvite.Header')}
                            </Heading>
                        </Hero>
                        {hasError && (
                            <>
                                <Text type={TextType.body}>{i18n.t('AnonymousInvite.Error')}</Text>
                                <Button
                                    text={i18n.t('Back')}
                                    onClick={() => window.location.replace('/')}
                                />
                            </>
                        )}
                        {done && (
                            <Group>
                                <MarkDown>{i18n.t('AnonymousInvite.Success')}</MarkDown>
                                <Button
                                    text={i18n.t('Back')}
                                    onClick={() => window.location.replace('/')}
                                />
                            </Group>
                        )}
                        {showForm && (
                            <>
                                <Group>
                                    <Text type={TextType.body}>
                                        {i18n.t('AnonymousInvite.Intro')}
                                    </Text>
                                    <Composite fillParent>
                                        <TextInput
                                            label={i18n.t('AnonymousInvite.Chargers')}
                                            name="connectors"
                                            value={numOfConnectors?.toString()}
                                            disabled
                                        />
                                        <TextInput
                                            label={i18n.t('AnonymousInvite.Description')}
                                            name="description"
                                            value={projectName}
                                            disabled
                                        />
                                    </Composite>
                                </Group>
                                <Group>
                                    <Text type={TextType.body}>
                                        {i18n.t('AnonymousInvite.Body')}
                                    </Text>
                                    <TextInput
                                        label={i18n.t('AnonymousInvite.EmailLabel')}
                                        name="email"
                                        value={email}
                                        onChange={setEmail}
                                        type="email"
                                        autocomplete="username"
                                        disabled={loadingSave}
                                        informationType={InformationType.ERROR}
                                        message={isEmailOk ? undefined : i18n.t('EmailInvalid')}
                                    />
                                    <Dropdown<string>
                                        name="country"
                                        label={i18n.t('AnonymousInvite.CountryLabel')}
                                        placeholder={i18n.t(
                                            'OrganizationSettings.CountryPlaceholder'
                                        )}
                                        onChange={setCountryCode}
                                        value={
                                            countries?.find(
                                                (c) => c.id === addressDetails?.countryCode
                                            )?.id
                                        }
                                        disabled={loadingSave}
                                        items={countries.map((c) => c.id)}
                                        keyExtractor={(id: string) => id}
                                        labelExtractor={(id: string) => i18n.t(`Countries.${id}`)}
                                    />
                                    <Message>
                                        <Composite gap={theme.spacingRaw(2)} align="start">
                                            <Icon
                                                icon="information"
                                                color={theme.background.attention}
                                                size={16}
                                            />
                                            <Text type={TextType.description}>
                                                {i18n.t('AnonymousInvite.Information')}
                                            </Text>
                                        </Composite>
                                    </Message>
                                </Group>
                                <Group>
                                    <Button
                                        text={i18n.t('AnonymousInvite.SubmitButton')}
                                        disabled={!isOk || loadingSave}
                                        type="submit"
                                        name="login-button"
                                        loading={loadingSave}
                                    />
                                </Group>
                            </>
                        )}
                    </Group>
                }
            />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    SplitScreen,
    Heading,
    Level,
    Button,
    Group,
    ActionRow,
    Size,
    Text,
    ProgressIndicator,
    Composite,
    ProgressType,
    Icon,
    useTheme,
    Card,
    IconName,
    TextType,
    Variant,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { BrandPanel } from '../../../fractions/brand-panel';

import i18n from '../../../i18n';
import { SetupMenu, Step } from '../../../fractions/setup-menu';
import { SET_DEFAULT, GET_CONNECTORS } from './reset.queries';
import { ChargeSystem } from '../../../models/charge-system';
import { ResetResponseCode } from '../../../models/charge-point';
import { UIState } from '../../../models/ui';
import { ConnectorStatus } from '../../../models/connector';
import { localeObjectSort } from '../../../utils/localeObjectSort';
import { getStatus } from '../../../utils/status';
import { Grid } from './reset.styles';
import { IconContainer } from '../../../fractions/group-section/group-section.styles';
import { getVisualStatus } from '../../../utils/visual-status';
import { getOCPPStatus } from '../../../utils/occp-status';

interface DefaultResponse {
    chargeSystem: {
        setDefault: {
            responseCodes: ResetResponseCode[];
            status: boolean;
        };
    };
}

interface ConnectorResponse {
    chargeSystem: ChargeSystem;
}

function getDescription(defaultOk: UIState, allConnectorsOk: UIState) {
    if (defaultOk === UIState.READY && allConnectorsOk !== UIState.READY) {
        return i18n.t('Reset.RestartingDescription');
    }
    if (defaultOk === UIState.ERROR) {
        return i18n.t('Reset.ErrorDescription');
    }
    if (defaultOk === UIState.READY && allConnectorsOk === UIState.READY) {
        return i18n.t('Reset.ReadyDescription');
    }
    return i18n.t('Reset.DefaultDescription');
}

export function Reset({
    headingLevel = Level.h1,
    buttonSize = Size.NORMAL,
}: {
    headingLevel: Level;
    buttonSize: Size;
}) {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const theme = useTheme();

    const [defaultOk, setDefaultOk] = useState<UIState>(UIState.LOADING);
    const [allConnectorsOk, setAllConnectorsOk] = useState<UIState>(UIState.LOADING);
    const [color, setColor] = useState(theme.colorSecondary);
    const [connectorInterVal, setConnectorInterval] = useState(5000);

    const [setDefault, { loading: loadingSetDefault, called, data }] = useLazyQuery<
        DefaultResponse
    >(SET_DEFAULT, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const { responseCodes, status: setDefaultSucceeded } = data?.chargeSystem?.setDefault ?? {};

    const [
        getConnectors,
        {
            data: connectorData,
            loading: loadingConnectorData,
            refetch,
            called: getConnectorsCalled,
        },
    ] = useLazyQuery<ConnectorResponse>(GET_CONNECTORS, {
        variables: { id },
        fetchPolicy: 'network-only',
        pollInterval: connectorInterVal,
    });
    const { connectors = [] } = connectorData?.chargeSystem ?? {};

    const submit = () => {
        history.push(`/charge-system/${id}/setup/groups`);
    };

    useEffect(() => {
        if (!called) {
            setDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            (!loadingSetDefault && !called) ||
            setDefaultSucceeded === undefined ||
            defaultOk === UIState.READY ||
            defaultOk === UIState.ERROR
        ) {
            return;
        }

        if (
            setDefaultSucceeded &&
            responseCodes?.includes(ResetResponseCode.REBOOT_REQUIRED) &&
            !loadingConnectorData
        ) {
            setDefaultOk(UIState.READY);
        } else if (
            setDefaultSucceeded &&
            responseCodes?.every((rc) => rc === ResetResponseCode.ACCEPTED)
        ) {
            setDefaultOk(UIState.READY);
        } else if (
            !setDefaultSucceeded ||
            !responseCodes?.every(
                (rc) =>
                    rc === ResetResponseCode.REBOOT_REQUIRED || rc === ResetResponseCode.ACCEPTED
            )
        ) {
            setDefaultOk(UIState.ERROR);
        }
        // get status of connectors
        getConnectors();
    }, [
        setDefaultSucceeded,
        responseCodes,
        getConnectors,
        loadingConnectorData,
        loadingSetDefault,
        called,
        defaultOk,
        allConnectorsOk,
    ]);

    const sortedConnectors = localeObjectSort(connectors, 'alias');

    useEffect(() => {
        if (
            !getConnectorsCalled ||
            (defaultOk === UIState.READY && allConnectorsOk === UIState.READY)
        ) {
            return;
        }

        const connectorsReady =
            connectors.length > 0 &&
            connectors.every(({ status }) => status === ConnectorStatus.AVAILABLE);

        if (connectorsReady) {
            setAllConnectorsOk(UIState.READY);
            setConnectorInterval(0);
        } else if (!loadingConnectorData && connectors?.length <= 0) {
            setAllConnectorsOk(UIState.ERROR);
            setConnectorInterval(0);
        }
    }, [
        allConnectorsOk,
        connectors,
        getConnectorsCalled,
        loadingConnectorData,
        refetch,
        defaultOk,
    ]);

    useEffect(() => {
        if (defaultOk === UIState.ERROR && allConnectorsOk === UIState.ERROR) {
            setColor(theme.errorColor);
        } else if (defaultOk === UIState.READY && allConnectorsOk === UIState.READY) {
            setColor(theme.successColor);
        }
    }, [defaultOk, theme, allConnectorsOk]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('AboutChargeSystem.FlowHeader')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <SetupMenu id={id} currentStep={Step.ABOUT_CHARGE_SYSTEM} />
                    </Group>
                </Group>
            }
            second={
                <Group maxWidth="640px">
                    <Group flex align="center" justify="center">
                        <Icon icon="boltCircle" color={color} size={60} />
                    </Group>
                    <Group>
                        <Composite>
                            <Heading level={headingLevel}>{i18n.t('Reset.Header')}</Heading>
                            {(defaultOk === UIState.LOADING ||
                                allConnectorsOk === UIState.LOADING) && (
                                <ProgressIndicator
                                    progress={50}
                                    type={ProgressType.DONUT_LOADING}
                                />
                            )}
                        </Composite>
                        <Text>{getDescription(defaultOk, allConnectorsOk)}</Text>
                    </Group>
                    <Group>
                        <Grid>
                            {sortedConnectors.map((connector) => {
                                const { hbTimeout } = connector?.operationalData || {};
                                const { version, status: connectorStatus, chargingState } =
                                    connector?.operationalData?.ocpp || {};
                                const ocpp = getOCPPStatus(version, connectorStatus, chargingState);
                                const isRestarting =
                                    connector.status === ConnectorStatus.RESTARTING;
                                const {
                                    background,
                                    titleColor,
                                    iconColor,
                                    icon,
                                    iconBackgroundColor,
                                    descriptionColor,
                                } = getVisualStatus(
                                    connectorStatus,
                                    theme,
                                    hbTimeout,
                                    isRestarting,
                                    connector?.type
                                );

                                return (
                                    <Card
                                        tid={`card-${connector.id}`}
                                        key={`Card-${connector.id}`}
                                        background={background}
                                        onClick={() => {
                                            refetch();
                                        }}
                                        thumbnail={
                                            isRestarting ? (
                                                <ProgressIndicator
                                                    progress={0}
                                                    type={ProgressType.DONUT_LOADING}
                                                />
                                            ) : (
                                                <IconContainer color={iconBackgroundColor}>
                                                    <Icon
                                                        icon={icon as IconName}
                                                        color={iconColor}
                                                        size={16}
                                                    />
                                                </IconContainer>
                                            )
                                        }
                                        title={
                                            <Heading level={Level.h4} color={titleColor}>
                                                {connector.alias}
                                            </Heading>
                                        }
                                    >
                                        <Text
                                            type={TextType.descriptionSmall}
                                            color={descriptionColor}
                                        >
                                            {i18n.t(
                                                `ChargerGroups.ConnectorStatus.${getStatus(
                                                    hbTimeout,
                                                    ocpp,
                                                    isRestarting
                                                )}.Title`
                                            )}
                                        </Text>
                                    </Card>
                                );
                            })}
                        </Grid>
                    </Group>
                    <ActionRow>
                        <Button
                            name="submit-button"
                            text={i18n.t('Continue')}
                            onClick={submit}
                            size={buttonSize}
                            variant={
                                defaultOk === UIState.READY && allConnectorsOk === UIState.READY
                                    ? Variant.PRIMARY
                                    : Variant.SECONDARY
                            }
                            disabled={defaultOk === UIState.LOADING}
                        />
                    </ActionRow>
                </Group>
            }
        />
    );
}

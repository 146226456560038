import { gql } from '@apollo/client';
import { AddressDetails, AnonymousSetupResponseStatus } from '../../models';

export interface ValidateInvitationResponse {
    anonymousInvite: {
        numOfConnectors: number;
        projectName: string;
        addressDetails: AddressDetails;
        status: AnonymousSetupResponseStatus;
    };
    countries: [
        {
            id: string;
        }
    ];
}

export interface AcceptInvitationResponse {
    acceptAnonymousInvite: {
        status: AnonymousSetupResponseStatus;
    };
}

export const VALIDATE = gql`
    query ValidateInvitation($token: String!) {
        anonymousInvite(token: $token) {
            numOfConnectors
            projectName
            addressDetails {
                streetNumber
                address
                zipCode
                city
                country
            }
            status
        }
        countries {
            id
        }
    }
`;

export const ACCEPT = gql`
    mutation ValidateInvitation($token: String!, $email: String!, $countryCode: String!) {
        acceptAnonymousInvite(input: { token: $token, email: $email, countryCode: $countryCode }) {
            status
        }
    }
`;

import { ConnectorType } from '../../models';

export function getConnectorTypeIcon(type?: ConnectorType): string {
    switch (type) {
        case ConnectorType.TYPE1:
            return 'typeType1';
        case ConnectorType.TYPE2_CABLE:
            return 'typeType2Cable';
        case ConnectorType.TYPE2_SOCKET:
            return 'typeType2Socket';
        case ConnectorType.CHADEMO:
            return 'typeChademo';
        case ConnectorType.CCS:
            return 'typeCCS';
        case ConnectorType.SHUKO:
        case ConnectorType.TESLA_SC:
        case ConnectorType.UNKNOWN:
        default:
            return 'boltCircle';
    }
}

import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
    SplitScreen,
    Heading,
    Level,
    TextInput,
    Button,
    Group,
    ActionRow,
    Dropdown,
    Composite,
    CheckBox,
    Country as CountryCode,
    SuggestType,
    Suggest,
    getCurrencyFromCountry,
    Text,
    TextType,
} from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { BrandPanel } from '../../../fractions/brand-panel';
import i18n from '../../../i18n';
import { OnboardingMenu, Step } from '../../../fractions/onboarding-menu';
import {
    CategoryResponseType,
    CountryResponseType,
    OrganizationResponseType,
    GET_COUNTRIES,
    GET_ORGANIZATION,
    GET_ORGANIZATION_CATEGORIES,
    UPSERT_ORGANIZATION,
} from './organization-info.queries';
import { useCountry, useCurrency } from '../../../utils/hooks';
import { validateEmail } from '../../../utils/email';
import { testPhonenumberPolicy } from '../../../utils/phonenumber-policy';
import { validateCustomerReference } from '../../../utils/customer-reference';

export function OrganizationInfo() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [, setCurrency] = useCurrency();
    const [, setCountry] = useCountry();

    const { data, loading } = useQuery<OrganizationResponseType>(GET_ORGANIZATION, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const { organization = { vatLiable: true } } = data ?? {};

    const { data: countryData, loading: countriesLoading } = useQuery<CountryResponseType>(
        GET_COUNTRIES
    );
    const { countries } = countryData ?? {};

    const { data: categoriesData = {}, loading: categoriesLoading } = useQuery<
        CategoryResponseType
    >(GET_ORGANIZATION_CATEGORIES);
    const { organizationCategories: availableCategories = [] } = categoriesData;

    const [upsertOrganization, { data: upsertedOrganization }] = useMutation(UPSERT_ORGANIZATION);

    const defaultOrg = React.useMemo(() => ({}), []);

    const {
        name: initialName,
        vatNr: initialVatNr,
        orgNr: initialOrgNr,
        category: initialCategory,
        contactAddress: initialAddress,
        billingAddress: initialBillingAddress,
        vatLiable: initialVatLiable,
        invoiceEmail: initialInvoiceEmail,
        contactEmail: initialContactEmail,
        contactPhone: initialContactPhone,
        customerNr,
        customerReference: initialCustomerReference,
    } = organization || defaultOrg;

    const [name, setName] = useState<string>();
    const [contactEmail, setContactEmail] = useState<string>('');
    const [contactPhone, setContactPhone] = useState<string>('');
    const [invoiceEmail, setInvoiceEmail] = useState<string>('');
    const [customerReference, setCustomerReference] = useState<string>('');
    const [vatNr, setVatNr] = useState<string>();
    const [orgNr, setOrgNr] = useState<string>();
    const [vatLiable, setVatLiable] = useState<boolean>(true);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [availableCountries, setAvailableCountries] = useState<string[]>([]);
    const [addressDetails, setAddressDetails] = useState<SuggestType>();
    const [billingSameAsAddress, setBillingSameAsAddress] = useState(true);
    const [billingAddress, setBillingAddress] = useState<SuggestType>();

    const { address, zipCode, city, streetNumber } = addressDetails ?? {};

    const vatOk = vatLiable ? vatNr : true;

    const invalid = !(
        name &&
        vatOk &&
        orgNr &&
        selectedCategory &&
        address &&
        zipCode &&
        city &&
        streetNumber &&
        validateEmail(invoiceEmail ?? '') &&
        validateEmail(contactEmail ?? '') &&
        testPhonenumberPolicy(contactPhone ?? '') &&
        validateCustomerReference(customerReference ?? '') &&
        addressDetails?.countryCode &&
        (billingSameAsAddress ||
            (billingAddress?.address &&
                billingAddress?.streetNumber &&
                billingAddress?.zipCode &&
                billingAddress?.city &&
                billingAddress?.countryCode))
    );

    useEffect(() => {
        setName(initialName);
        setInvoiceEmail(initialInvoiceEmail ?? '');
        setContactEmail(initialContactEmail ?? '');
        setContactPhone(initialContactPhone ?? '');
        setCustomerReference(initialCustomerReference ?? '');
        setVatNr(initialVatNr);
        setOrgNr(initialOrgNr);
        setVatLiable(!!initialVatLiable);
        setSelectedCategory(initialCategory);
        setAvailableCountries(countries?.map((c) => c.id) ?? []);
        setAddressDetails({
            ...initialAddress,
            countryCode: initialAddress?.country,
        });
        setBillingAddress({
            ...initialBillingAddress,
            countryCode: initialBillingAddress?.country,
        });
        setBillingSameAsAddress(
            !initialBillingAddress ||
                JSON.stringify(initialAddress || {}) === JSON.stringify(initialBillingAddress || {})
        );
    }, [
        initialAddress,
        initialName,
        initialVatNr,
        initialOrgNr,
        initialBillingAddress,
        initialCategory,
        initialVatLiable,
        availableCategories,
        countries,
        initialInvoiceEmail,
        initialContactEmail,
        initialContactPhone,
        initialCustomerReference,
    ]);

    const updateAddressDetailsPart = (newDetails: Partial<SuggestType>) =>
        setAddressDetails((currentAddressDetails) => ({
            ...currentAddressDetails,
            ...newDetails,
        }));
    const updateBillingAddressPart = (newDetails: Partial<SuggestType>) =>
        setBillingAddress((currentAddressDetails) => {
            const updatedBillingAddress = { ...currentAddressDetails, ...newDetails };
            // Remove latitude and longitude
            const { latitude, longitude, ...addressWithoutLatLong } = updatedBillingAddress;
            return addressWithoutLatLong;
        });

    const onNameChanged = (newName: string) => setName(newName);
    const onContactEmailChanged = (newContactEmail: string) => setContactEmail(newContactEmail);
    const onContactPhoneChanged = (newContactPhone: string) => setContactPhone(newContactPhone);
    const onInvoiceEmailChanged = (newInvoiceEmail: string) => setInvoiceEmail(newInvoiceEmail);
    const onVatNrChanged = (newOrgNumber: string) => setVatNr(newOrgNumber);
    const onOrgNrChanged = (newOrgNr: string) => setOrgNr(newOrgNr);
    const onVatLiableChanged = (newVatLiable: boolean) => setVatLiable(newVatLiable);
    const onCategoryChanged = (newCategory: string) => setSelectedCategory(newCategory);

    const onAddressChanged = (newAddress: string) =>
        updateAddressDetailsPart({ address: newAddress, description: newAddress });
    const onStreetNumberChanged = (newStreetNumber: string) =>
        updateAddressDetailsPart({ streetNumber: newStreetNumber });
    const onZipCodeChanged = (newZipCode: string) =>
        updateAddressDetailsPart({ zipCode: newZipCode });
    const onCityChanged = (newCity: string) => updateAddressDetailsPart({ city: newCity });
    const onCountryChanged = (newCountry: string) =>
        updateAddressDetailsPart({ countryCode: newCountry });

    const onSelectAddressChanged = (newSelectedAddress: SuggestType) =>
        setAddressDetails(newSelectedAddress);

    const onBillingSameAsAddressChanged = (checked: boolean) => {
        if (!checked) {
            setBillingAddress(addressDetails);
        }
        setBillingSameAsAddress(checked);
    };

    const onBillingStreetChanged = (newAddress: string) =>
        updateBillingAddressPart({ address: newAddress });
    const onBillingZipCodeChanged = (newZip: string) =>
        updateBillingAddressPart({ zipCode: newZip });
    const onBillingCityChanged = (newCity: string) => updateBillingAddressPart({ city: newCity });
    const onBillingCountryChanged = (newCountry: string) =>
        updateBillingAddressPart({ countryCode: newCountry });
    const onBillingStreetNumberChanged = (newStreetNumber: string) =>
        updateBillingAddressPart({ streetNumber: newStreetNumber });

    const submit = useCallback(() => {
        // Manually mapping fields to get rid of __typename automatically added to result from graphql query.
        const addressToSet = {
            streetNumber: addressDetails?.streetNumber,
            address: addressDetails?.address,
            zipCode: addressDetails?.zipCode,
            city: addressDetails?.city,
            latitude: addressDetails?.latitude,
            longitude: addressDetails?.longitude,
            country: addressDetails?.countryCode,
        };

        const billingAddressToSet = {
            streetNumber: billingAddress?.streetNumber,
            address: billingAddress?.address,
            zipCode: billingAddress?.zipCode,
            city: billingAddress?.city,
            latitude: billingAddress?.latitude,
            longitude: billingAddress?.longitude,
            country: billingAddress?.countryCode,
        };

        upsertOrganization({
            variables: {
                id,
                name,
                category: selectedCategory || null,
                vatNr,
                orgNr,
                customerReference,
                contactAddress: addressToSet,
                billingAddress: billingSameAsAddress ? addressToSet : billingAddressToSet,
                vatLiable,
                invoiceEmail,
                contactEmail,
                contactPhone,
            },
        });
    }, [
        upsertOrganization,
        id,
        name,
        selectedCategory,
        vatNr,
        orgNr,
        addressDetails,
        billingSameAsAddress,
        billingAddress,
        vatLiable,
        invoiceEmail,
        contactEmail,
        contactPhone,
        customerReference,
    ]);

    useEffect(() => {
        if (upsertedOrganization && upsertedOrganization.upsertOrganization) {
            setCurrency(getCurrencyFromCountry(addressDetails?.countryCode as CountryCode));
            setCountry(addressDetails?.countryCode as CountryCode);
            history.push(`/onboarding/subscription/${upsertedOrganization.upsertOrganization.id}`);
        }
    }, [upsertedOrganization, history, setCurrency, setCountry, addressDetails]);

    return (
        <SplitScreen
            first={
                <Group>
                    <Group>
                        <BrandPanel
                            heading={i18n.t('BrandPanel.Header')}
                            subHeading={i18n.t('BrandPanel.SubHeader')}
                        />
                        <OnboardingMenu id={id} currentStep={Step.ORGANIZATION} />
                    </Group>
                </Group>
            }
            secondSideLoading={loading || categoriesLoading || countriesLoading}
            second={
                <Group minWidth="480px">
                    <Group>
                        <Heading level={Level.h1}>{i18n.t('SetUpOrganization.Header')}</Heading>
                        <ReactMarkdown>{i18n.t('SetUpOrganization.Description')}</ReactMarkdown>
                    </Group>
                    <Group>
                        <Dropdown<string>
                            name="country"
                            label={i18n.t('SetUpOrganization.CountryLabel')}
                            placeholder={i18n.t('SetUpOrganization.CountryPlaceholder')}
                            onChange={onCountryChanged}
                            value={availableCountries.find(
                                (c: string) => c === addressDetails?.countryCode
                            )}
                            items={availableCountries}
                            keyExtractor={(countryCode: string) => countryCode}
                            labelExtractor={(countryCode: string) =>
                                i18n.t(`Countries.${countryCode}`)
                            }
                        />
                        <TextInput
                            name="customer-nr"
                            label={i18n.t('SetUpOrganization.CustomerNrLabel')}
                            value={customerNr}
                            readOnly
                        />
                        <TextInput
                            name="name"
                            label={i18n.t('SetUpOrganization.OrganizationNameLabel')}
                            value={name}
                            onChange={onNameChanged}
                        />
                        <CheckBox
                            name="organizationVAT"
                            label={i18n.t('SetUpOrganization.VATLiable')}
                            checked={vatLiable}
                            onChange={onVatLiableChanged}
                        />
                        {vatLiable && (
                            <TextInput
                                name="vat-nr"
                                label={i18n.t('SetUpOrganization.VatNrLabel')}
                                value={vatNr}
                                onChange={onVatNrChanged}
                            />
                        )}
                        <TextInput
                            name="org-nr"
                            label={i18n.t('SetUpOrganization.OrgNrLabel')}
                            value={orgNr}
                            onChange={onOrgNrChanged}
                        />
                        <TextInput
                            name="contact-email"
                            label={i18n.t('SetUpOrganization.ContactEmailLabel')}
                            value={contactEmail}
                            onChange={onContactEmailChanged}
                            message={
                                validateEmail(contactEmail) ? undefined : i18n.t('EmailInvalid')
                            }
                        />
                        <TextInput
                            name="contact-phone"
                            label={i18n.t('SetUpOrganization.ContactPhoneLabel')}
                            value={contactPhone}
                            onChange={onContactPhoneChanged}
                            message={
                                testPhonenumberPolicy(contactPhone)
                                    ? undefined
                                    : i18n.t('PhoneNumberInvalid')
                            }
                        />
                        <Text type={TextType.descriptionSmall}>
                            {i18n.t('PhoneNumberFormatDescription')}
                        </Text>
                        <TextInput
                            name="invoice-email"
                            label={i18n.t('SetUpOrganization.InvoiceEmailLabel')}
                            value={invoiceEmail}
                            onChange={onInvoiceEmailChanged}
                            message={
                                validateEmail(invoiceEmail) ? undefined : i18n.t('EmailInvalid')
                            }
                        />
                        <TextInput
                            name="customer-reference"
                            label={i18n.t('OrganizationSettings.CustomerReferenceLabel')}
                            value={customerReference}
                            onChange={setCustomerReference}
                            message={
                                validateCustomerReference(customerReference)
                                    ? undefined
                                    : i18n.t('OrganizationSettings.CustomerReferenceError')
                            }
                        />

                        <Dropdown<string>
                            name="category"
                            label={i18n.t('SetUpOrganization.CategoryLabel')}
                            placeholder={i18n.t('SetUpOrganization.CategoryPlaceholder')}
                            onChange={onCategoryChanged}
                            value={selectedCategory}
                            items={availableCategories}
                            keyExtractor={(item: string) => item}
                            labelExtractor={(item: string) =>
                                i18n.t(`SetUpOrganization.Category.${item}`)
                            }
                        />
                    </Group>
                    <Group>
                        <Heading level={Level.h4}>
                            {i18n.t('SetUpOrganization.AddressHeader')}
                        </Heading>
                        <Composite fillParent>
                            <Suggest
                                name="street-address"
                                label={i18n.t('SetUpOrganization.StreetAddressLabel')}
                                value={address}
                                onChange={onAddressChanged}
                                onSelect={onSelectAddressChanged}
                            />

                            <TextInput
                                name="streetNumber"
                                label={i18n.t('SetUpOrganization.StreetNumberLabel')}
                                value={streetNumber}
                                onChange={onStreetNumberChanged}
                                flex="0 1"
                            />
                        </Composite>
                        <Composite>
                            <TextInput
                                name="zip-code"
                                label={i18n.t('SetUpOrganization.ZipCodeLabel')}
                                value={zipCode}
                                onChange={onZipCodeChanged}
                                flex="0 1"
                            />
                            <TextInput
                                name="city"
                                label={i18n.t('SetUpOrganization.CityLabel')}
                                value={city}
                                onChange={onCityChanged}
                            />
                        </Composite>
                    </Group>
                    <Group>
                        <Composite>
                            <Heading level={Level.h4}>
                                {i18n.t('SetUpOrganization.BillingAddressHeader')}
                            </Heading>
                            <CheckBox
                                name="same-as-address"
                                label={i18n.t('SetUpOrganization.SameAsAddressLabel')}
                                fillContainer={false}
                                checked={billingSameAsAddress}
                                onChange={onBillingSameAsAddressChanged}
                            />
                        </Composite>
                        {!billingSameAsAddress && (
                            <>
                                <Composite>
                                    <TextInput
                                        name="billing-street-address"
                                        label={i18n.t('SetUpOrganization.StreetAddressLabel')}
                                        value={billingAddress?.address}
                                        onChange={onBillingStreetChanged}
                                    />
                                    <TextInput
                                        name="streetNumber"
                                        label={i18n.t('SetUpOrganization.StreetNumberLabel')}
                                        value={billingAddress?.streetNumber}
                                        onChange={onBillingStreetNumberChanged}
                                        flex="0 1"
                                    />
                                </Composite>
                                <Composite>
                                    <TextInput
                                        name="billing-zip-code"
                                        label={i18n.t('SetUpOrganization.ZipCodeLabel')}
                                        value={billingAddress?.zipCode}
                                        onChange={onBillingZipCodeChanged}
                                        flex="0 1"
                                    />
                                    <TextInput
                                        name="billing-city"
                                        label={i18n.t('SetUpOrganization.CityLabel')}
                                        value={billingAddress?.city}
                                        onChange={onBillingCityChanged}
                                    />
                                </Composite>
                                <Dropdown<string>
                                    name="billing-country"
                                    label={i18n.t('SetUpOrganization.CountryLabel')}
                                    placeholder={i18n.t('SetUpOrganization.CountryPlaceholder')}
                                    onChange={onBillingCountryChanged}
                                    value={availableCountries.find(
                                        (c: string) => c === billingAddress?.countryCode
                                    )}
                                    items={availableCountries}
                                    keyExtractor={(countryCode: string) => countryCode}
                                    labelExtractor={(countryCode: string) =>
                                        i18n.t(`Countries.${countryCode}`)
                                    }
                                />
                            </>
                        )}
                    </Group>
                    <ActionRow>
                        <Button
                            name="submit-button"
                            text={i18n.t('Continue')}
                            disabled={invalid}
                            onClick={submit}
                        />
                    </ActionRow>
                </Group>
            }
        />
    );
}

import { StatusLogType } from './log';

export enum ConnectorType {
    'TYPE1' = 'TYPE1',
    'TYPE2_CABLE' = 'TYPE2_CABLE',
    'TYPE2_SOCKET' = 'TYPE2_SOCKET',
    'TYPE3' = 'TYPE3',
    'CHADEMO' = 'CHADEMO',
    'CCS' = 'CCS',
    'SHUKO' = 'SHUKO',
    'TESLA_SC' = 'TESLA_SC',
    'UNKNOWN' = 'UNKNOWN',
}

export enum ConnectorStatus {
    'AVAILABLE' = 'AVAILABLE',
    'OCCUPIED' = 'OCCUPIED',
    'FAULTED' = 'FAULTED',
    'UNAVAILABLE' = 'UNAVAILABLE',
    'RESERVED' = 'RESERVED',
    'FINISHING' = 'FINISHING',
    'PREPARING' = 'PREPARING',
    'CHARGING' = 'CHARGING',
    'SUSPENDED_EV' = 'SUSPENDED_EV',
    'SUSPENDED_EVSE' = 'SUSPENDED_EVSE',
    'RESTARTING' = 'RESTARTING',
    'FACILITY_FINISHING' = 'FACILITY_FINISHING',
}

export enum OCCPStatus {
    'OCPP15_UNAVAILABLE' = 'OCPP15_UNAVAILABLE',
    'OCPP15_AVAILABLE' = 'OCPP15_AVAILABLE',
    'OCPP15_OCCUPIED' = 'OCPP15_OCCUPIED',
    'OCPP15_FAULTED' = 'OCPP15_FAULTED',
    'OCPP15_RESERVED' = 'OCPP15_RESERVED',
    'OCPP16_UNAVAILABLE' = 'OCPP16_UNAVAILABLE',
    'OCPP16_FAULTED' = 'OCPP16_FAULTED',
    'OCPP16_RESERVED' = 'OCPP16_RESERVED',
    'OCPP16_AVAILABLE' = 'OCPP16_AVAILABLE',
    'OCPP16_PREPARING' = 'OCPP16_PREPARING',
    'OCPP16_CHARGING' = 'OCPP16_CHARGING',
    'OCPP16_SUSPENDED_EVSE' = 'OCPP16_SUSPENDED_EVSE',
    'OCPP16_SUSPENDED_EV' = 'OCPP16_SUSPENDED_EV',
    'OCPP16_FINISHING' = 'OCPP16_FINISHING',
    'OCPP20_UNAVAILABLE' = 'OCPP20_UNAVAILABLE',
    'OCPP20_AVAILABLE' = 'OCPP20_AVAILABLE_IDLE',
    'OCPP20_AVAILABLE_IDLE' = 'OCPP20_AVAILABLE_IDLE',
    'OCPP20_OCCUPIED_EVCONNECTED' = 'OCPP20_OCCUPIED_EVCONNECTED',
    'OCPP20_OCCUPIED_IDLE' = 'OCPP20_OCCUPIED_IDLE',
    'OCPP20_OCCUPIED_CHARGING' = 'OCPP20_OCCUPIED_CHARGING',
    'OCPP20_OCCUPIED_SUSPENDEDEVSE' = 'OCPP20_OCCUPIED_SUSPENDEDEVSE',
    'OCPP20_OCCUPIED_SUSPENDEDEV' = 'OCPP20_OCCUPIED_SUSPENDEDEV',
    'OCPP20_FAULTED' = 'OCPP20_FAULTED',
    'OCPP20_RESERVED' = 'OCPP20_RESERVED',
    'OFFLINE' = 'OFFLINE',
    'FACILITY_FINISHING' = 'FACILITY_FINISHING',
}

export enum EnergyMeterAvailable {
    'PRESENT' = 'PRESENT',
    'ABSENT' = 'ABSENT',
    'UNKNOWN' = 'UNKNOWN',
}

export type Ocpp = {
    chargingState: string;
    status: string;
    version: string;
};

export type OperationalData = {
    ocpp: Ocpp;
    hbTimeout: boolean;
    hbLastAlive: string;
};
export type Connector = {
    id: string;
    displayName: string;
    alias: string;
    status: ConnectorStatus;
    power: number;
    type: ConnectorType;
    isPublic: boolean;
    operationalData: OperationalData;
    connectorNumber?: number;
    statusLog?: StatusLogType;
    eventLogUrl?: string;
    chargePointId?: string;
    energyMeterAvailable?: EnergyMeterAvailable;
    meterValue?: number;
};

export interface ConectorVisualStatus {
    background: string;
    icon: string;
    iconActive: string;
    iconColor: string;
    iconBackgroundColor: string;
    iconColorActive: string;
    titleColor: string;
    descriptionColor: string;
    start: boolean;
    stop: boolean;
    help: boolean;
}

import React from 'react';
import {
    Button,
    Group,
    Heading,
    Icon,
    IconName,
    Level,
    Text,
    TextType,
    useTheme,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { Root, Title, InfoBoxRoot } from './charge-system-item.styles';
import { Connector, OCCPStatus } from '../../models/connector';
import { ConfigurationStatus } from '../../models/organization';
import { getOCPPStatus } from '../../utils/occp-status';

function InfoBox({
    icon,
    title,
    description,
}: {
    icon: IconName;
    title: string;
    description: string;
}) {
    const theme = useTheme();
    return (
        <InfoBoxRoot>
            <Group tight>
                <Icon icon={icon} color={theme.linkTextColor} />
                <div>
                    <Heading level={Level.h4}>{title}</Heading>
                    <Text type={TextType.description}>{description}</Text>
                </div>
            </Group>
        </InfoBoxRoot>
    );
}

function InfoSection({ chargeSystem }: { chargeSystem: any }) {
    const { connectors = [] } = chargeSystem;
    return (
        <div>
            <InfoBox
                icon="car"
                title={i18n.t('ChargeSystemItem.ActiveChargers', [
                    connectors.filter((c: Connector) => {
                        const { version, status, chargingState } = c?.operationalData?.ocpp || {};
                        const ocpp = getOCPPStatus(version, status, chargingState);
                        return (
                            ocpp !== OCCPStatus.OFFLINE &&
                            ocpp !== OCCPStatus.OCPP15_AVAILABLE &&
                            ocpp !== OCCPStatus.OCPP15_FAULTED &&
                            ocpp !== OCCPStatus.OCPP15_UNAVAILABLE &&
                            ocpp !== OCCPStatus.OCPP16_AVAILABLE &&
                            ocpp !== OCCPStatus.OCPP16_FAULTED &&
                            ocpp !== OCCPStatus.OCPP20_AVAILABLE_IDLE &&
                            ocpp !== OCCPStatus.OCPP20_FAULTED &&
                            ocpp !== OCCPStatus.OCPP20_UNAVAILABLE &&
                            ocpp !== ('' as OCCPStatus)
                        );
                    }).length,
                    connectors.length,
                ])}
                description={i18n.t('ChargeSystemItem.Description')}
            />
        </div>
    );
}

export function ChargeSystemItem({
    chargeSystem,
    setupClick,
    itemClick,
}: {
    chargeSystem: any;
    setupClick: (id: string) => ((e: MouseEvent) => void) | undefined;
    itemClick: (id: string) => (() => void) | undefined;
}) {
    const setupRequired = chargeSystem.configurationStatus === ConfigurationStatus.SETUP_REQUIRED;

    return (
        <Root
            key={chargeSystem.id}
            onClick={itemClick(chargeSystem.id)}
            clickable={!setupRequired}
            data-test-id={`charge-system-${chargeSystem.id}`}
        >
            <Title>
                <Heading level={Level.h3}>
                    {chargeSystem.name ?? i18n.t('ChargeSystemItem.NewChargeSystem')}
                </Heading>
                <Text type={TextType.description}>{chargeSystem.address}</Text>
            </Title>
            {setupRequired ? (
                <Button
                    text={i18n.t('ChargeSystemItem.SetUp')}
                    name={`setup-button-${chargeSystem.id}`}
                    onClick={setupClick(chargeSystem.id) as () => void}
                />
            ) : (
                <InfoSection chargeSystem={chargeSystem} />
            )}
        </Root>
    );
}
